import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ThemeService } from '@core/services/theme/theme.service';
import { IdleModule } from '@features/idle';
import { MimicUserModule } from '@features/mimic-user';
import { OrganizationsModule } from '@features/organizations';
import { SecurityModule } from '@features/security';
import { SharedModule } from '@shared/shared.module';
import {
  ErrorPageContentComponent,
  ExternalAccessLayoutComponent,
  HomeComponent,
  InternalAccessLayoutComponent,
  MainComponent,
  Page403Component,
  Page404Component,
  PageReloadComponent,
  PageUnknownErrorComponent,
  UnAuthenticatedComponent,
} from './components';

const components = [
  HomeComponent,
  MainComponent,
  InternalAccessLayoutComponent,
  ExternalAccessLayoutComponent,
  ErrorPageContentComponent,
  Page403Component,
  Page404Component,
  PageReloadComponent,
  PageUnknownErrorComponent,
  UnAuthenticatedComponent,
];

@NgModule({
  declarations: [...components],
  providers: [ThemeService],
  exports: [...components],
  imports: [CommonModule, RouterModule, SecurityModule, MimicUserModule, SharedModule, IdleModule, OrganizationsModule],
})
export class LayoutModule {}
