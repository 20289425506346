import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { DataSourceDataService, SelectedOrganizationService, ThemeService, UserService } from '@core/services';
import {
  ServerSideAuthenticationModule,
  internalUserInterceptor,
  jwtInterceptor,
} from '@features/server-side-authentication';
import { EnsureModuleLoadedOnceGuard } from './ensure-module-loaded-once-guard';
import { httpErrorInterceptor } from './interceptors/http-error.interceptor';
import { DefaultOrganizationService } from './services';

@NgModule({
  declarations: [],
  imports: [CommonModule, ServerSideAuthenticationModule],
  providers: [
    provideHttpClient(withInterceptors([jwtInterceptor, internalUserInterceptor, httpErrorInterceptor])),
    UserService,
    ThemeService,
    SelectedOrganizationService,
    DataSourceDataService,
    DefaultOrganizationService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
