import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { API_BASE_URL } from '@core/injectors';
import { GlobalErrorHandlerService } from '@core/services';
import { InfoMessagesModule } from '@features/info-messages';
import { MimicUserModule } from '@features/mimic-user';
import { SecurityModule } from '@features/security';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { SentryModule } from './sentry/sentry.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    ReactiveFormsModule,
    CoreModule,
    SecurityModule.forRoot(API_BASE_URL, 'no-access'),
    AppRoutingModule,
    SharedModule,
    InfoMessagesModule,
    LayoutModule,
    MimicUserModule.forRoot(),
    SentryModule,
  ],
  providers: [
    provideExperimentalZonelessChangeDetection(),
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: true, minWidth: '560px', maxWidth: '1024px', disableClose: false },
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: 500,
        hideDelay: 500,
        touchendHideDelay: 500,
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
